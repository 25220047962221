import { applyMiddleware, legacy_createStore as createStore, compose } from "redux";
import {thunk} from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducer";

// import { createLogger } from "redux-logger";
// const loggerMiddleware = createLogger();

export default function configureStore(preloadedState: {} = {}) {
  // const middlewares = [thunk];
  // Todo: Enable it only for dev env.
  // const middlewares = [thunkMiddleware, loggerMiddleware];
  // const middlewareEnhancer = composeWithDevTools(applyMiddleware(thunk));

  // const enhancers = [middlewareEnhancer];
  // @ts-ignore: Unreachable code error
  return createStore(rootReducer, preloadedState, applyMiddleware(thunk))
}
