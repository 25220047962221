import { Box } from '@mui/material';
import React, { lazy, Suspense, useEffect } from 'react';
import Layout from './components/Layout';
// import { PrivateRoute } from './PrivateRoute';
import { APP_ROUTES } from './utils/constants';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { useDispatch } from 'react-redux';


const Dashboard = lazy(() => import('./views/Dashboard'));
const Logs = lazy(() => import('./views/Logs'));
const Status = lazy(() => import('./views/Status'));
const Analytics = lazy(() => import('./views/Analytics'));
const Billing = lazy(() => import('./views/Billing'))
const Integrations = lazy(() => import('./views/Integrations'))
const NewIntegration = lazy(() => import('./views/Integrations/NewIntegration'))
const Support = lazy(() => import('./views/Support'))
const Settings = lazy(() => import('./views/Settings'))
const Login = lazy(() => import('./Login'));

type RouteComponentProps = {};

const RouteComponent: React.FC<RouteComponentProps> = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isloggedIn');
    const expiration = localStorage.getItem('expiration');

    if (isLoggedIn && expiration) {
      if (Date.now() > parseInt(expiration)) {
        // Session expired
        localStorage.removeItem('isloggedIn');
        localStorage.removeItem('expiration');
        // dispatch({type: 'LOGOUT'});
        history.push('/login'); // Redirect to login
      }
    } 
    else {
      dispatch({type: 'LOGOUT'});
    }
  }, [dispatch, history]);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Layout>
        <Suspense fallback={
              <Box position="absolute" top="50%" left="50%" zIndex={9999}>
                loading...
              </Box>
            }>
            <Switch>
              {/* Analytics screen urls */}
              <Redirect exact from="/" to={APP_ROUTES.dashboard} />
              <Route path={APP_ROUTES.login} component={Login} />
              <PrivateRoute exact path={APP_ROUTES.dashboard} component={Dashboard} />
              <PrivateRoute exact path={APP_ROUTES.status} component={Status} />
              <PrivateRoute exact path={APP_ROUTES.logs} component={Logs} />
              <PrivateRoute exact path={APP_ROUTES.analytics} component={Analytics} />
              <PrivateRoute exact path={APP_ROUTES.invoices} component={Billing} />
              <PrivateRoute exact path={APP_ROUTES.integrations} component={Integrations} />
              <PrivateRoute exact path={APP_ROUTES.newIntegration} component={NewIntegration} />
              <PrivateRoute exact path={`${APP_ROUTES.integrationSettings}/:id`} component={NewIntegration} />
              <PrivateRoute exact path={APP_ROUTES.support} component={Support} />
              <PrivateRoute exact path={APP_ROUTES.settings} component={Settings} />
              {/* <Redirect from="/" to={APP_ROUTES.dashboard} /> */}
            </Switch>
            </Suspense>
        </Layout>
      </BrowserRouter>
    </React.Fragment>
  );
};
export default RouteComponent;
