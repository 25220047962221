import React from "react";

const CopyIcon = (props: any) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M13.5 2.5L5.5 2.5C5.36739 2.5 5.24021 2.55268 5.14645 2.64645C5.05268 2.74021 5 2.86739 5 3V5.5H2.5C2.36739 5.5 2.24021 5.55268 2.14645 5.64645C2.05268 5.74021 2 5.86739 2 6L2 14C2 14.1326 2.05268 14.2598 2.14645 14.3536C2.24021 14.4473 2.36739 14.5 2.5 14.5L10.5 14.5C10.6326 14.5 10.7598 14.4473 10.8536 14.3536C10.9473 14.2598 11 14.1326 11 14V11.5H13.5C13.6326 11.5 13.7598 11.4473 13.8536 11.3536C13.9473 11.2598 14 11.1326 14 11L14 3C14 2.86739 13.9473 2.74021 13.8536 2.64645C13.7598 2.55268 13.6326 2.5 13.5 2.5ZM10 13.5L3 13.5L3 6.5L10 6.5L10 13.5ZM13 10.5L11 10.5L11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5L6 5.5V3.5L13 3.5L13 10.5Z"
        fill={fill||"#1B2121"}
      />
    </svg>
  );
};

export default CopyIcon;
