import React from "react";

const FilterIcon = (props: any) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.6187 4.64346C21.5033 4.37675 21.312 4.14986 21.0686 3.99101C20.8253 3.83216 20.5406 3.74836 20.25 3.75002H3.74997C3.45966 3.7506 3.17575 3.8354 2.93269 3.99416C2.68962 4.15291 2.49785 4.37879 2.38063 4.64438C2.26341 4.90998 2.22579 5.20389 2.27232 5.49045C2.31886 5.77701 2.44755 6.04391 2.64279 6.25877L2.65029 6.26721L8.99998 13.0472V20.25C8.99991 20.5215 9.07353 20.7879 9.21297 21.0208C9.35241 21.2538 9.55246 21.4445 9.79178 21.5727C10.0311 21.7008 10.3007 21.7617 10.5719 21.7487C10.843 21.7356 11.1056 21.6493 11.3315 21.4988L14.3315 19.4981C14.5372 19.3612 14.7058 19.1755 14.8224 18.9576C14.939 18.7398 15 18.4965 15 18.2494V13.0472L21.3506 6.26721L21.3581 6.25877C21.5554 6.04489 21.6853 5.77764 21.7317 5.49037C21.778 5.2031 21.7387 4.90854 21.6187 4.64346ZM13.7043 12.2419C13.5746 12.3795 13.5016 12.5609 13.5 12.75V18.2494L10.5 20.25V12.75C10.5 12.5596 10.4276 12.3762 10.2975 12.2372L3.74997 5.25002H20.25L13.7043 12.2419Z"
        fill={fill || "#1B2121"}
      />
    </svg>
  );
};

export default FilterIcon;
