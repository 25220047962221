import { post } from "./common";
// import config from "../config";
import { MyThunkAction } from "../types";
import { ON_PAGINATION_LIST_LOADING, ON_PAGINATION_LIST_RESPONSE } from "./PaginationActions";



export const ON_LOGS_LIST_LOAD = "ON_LOGS_LIST_LOAD";
export const GET_API_DETAILS = "GET_API_DETAILS";
export const GET_MEAT_DATA = "GET_MEAT_DATA";
export const CLEAR_API_DETAILS ="CLEAR_API_DETAILS"
export const ON_LOGS_LOADING_START = "ON_LOGS_LOADING_START";
export const ON_LOGS_LOADING_FINISHED = "ON_LOGS_LOADING_FINISHED";
export const ON_LOADING_START = "ON_LOADING_START";
export const ON_LOADING_FINISHED = "ON_LOADING_FINISHED";
export const SET_QUERY_DATA = "SET_QUERY_DATA";

export const clearApiData = () => ({
  type: CLEAR_API_DETAILS,
});

export const setQueryData = (queryString?:any, forceReset?:boolean) => ({
  type:SET_QUERY_DATA,
  payload: queryString
});

export const getLogsListing = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {  

  dispatch({ type: ON_LOGS_LOADING_START, payload:true });

  const response = await post(`/tz-portal-api-dev/v1/metrics/list`,queryString)  

  dispatch({
    type: ON_LOGS_LIST_LOAD,
    payload: {
      forceReset,
      queryString: queryString,
      response: response
    },

  });
  dispatch({ type: ON_LOGS_LOADING_FINISHED, payload:false });
  return { logs:response, status: 200 };
};

export const getApiDetails = (queryString?:any, forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

  dispatch({ type: ON_LOADING_START, payload:true });

  const response = await post(`/tz-portal-api-dev/v1/metrics/action`,queryString)  

  dispatch({
    forceReset,
    type: GET_API_DETAILS,
    payload: {forceReset,response:response},
  });

  // console.log(response);
  dispatch({ type: ON_LOADING_FINISHED, payload:false });
  return { status: 200 };
};


export const getmetaData = (queryString?:any): MyThunkAction => async (dispatch: any) => {

  const response = await post(`/tz-portal-api-dev/v1/metrics/metadata`,queryString)  

  dispatch({
    type: GET_MEAT_DATA,
    payload: response,
  });

  // console.log(response);
  return { status: 200 };
};



