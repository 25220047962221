import { ActionType } from "./types";
import * as actionTypes from "../actions";
import { size } from "lodash";

type ScrollPaginationType = {
  isLoading: boolean;
  items: any[];
  count: number;
  page: number;
  size: number;
  progressCount: number;
  status: any;
  localItems: any[];
};

const initialState: ScrollPaginationType = {
  isLoading: false,
  items: [],
  count: 0,
  page: 0,
  size: 0,
  progressCount: 0,
  status: "",
  localItems: [],
};

const scrollPagination = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.RESET_PAGINATON_STATE: {
      return initialState;
    }
    case actionTypes.ON_PAGINATION_LIST_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case actionTypes.SET_PAGINATION_OFFSET: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    case actionTypes.ON_PAGINATION_LIST_RESPONSE: {
      const { items = [], count, forceReset, updateProgressCount, } = action.payload;

      return {
        ...state,
        count,
        page: forceReset ? 0 : action.payload.currentPage,
        size: forceReset ? 9 : action.payload.pageSize,
        progressCount: updateProgressCount ? count : state.progressCount,
        items: [...(forceReset ? [] : state.items), ...items],
      };
    }

    case actionTypes.ON_PAGINATION_LIST_LOCAL_RESPONSE: {
      const { localItems, count, status } = action.payload;
      return {
        ...state,
        localItems,
        page: 0,
        size: 20,
        count,
        status,
        items: localItems?.slice(0, 15),
      };
    }
    case actionTypes.ON_PAGINATION_LIST_LOCAL: {
      const { page, forceReset, size = 10 } = action.payload;
      return {
        ...state,
        page,
        items: [
          ...(forceReset ? [] : state.localItems?.slice(0, page * size)),
          ...state.localItems?.slice(page * size, size * page + size),
        ],
      };
    }
    case actionTypes.ON_ITEM_DELETE_SUCCESS: {
      const { docId } = action.payload;
      return {
        ...state,
        count: state.count - 1,
        items: state.items.filter((item: any) => {
          if (item.docId === docId) {
            return false;
          }
          return true;
        }),
      };
    }
    default: {
      return state;
    }
  }
};

export default scrollPagination;
