import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Box,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  useTheme,
} from "@mui/material";
import {
  CircleSharp,
  NotificationAddSharp,
} from "@mui/icons-material";
import DropdownSelect from "../DropdownSelect";
import ToolbarSelect from "./ToolbarSelect";
import { QuestionIcon } from "../SvgIcons";
import { ColorModeContext } from "../../App";
import { light } from "@mui/material/styles/createPalette";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import DropdownSingleSelect from "../DropdownSingleSelect";


export const AppBarWrap = styled(AppBar)(({ theme}: { theme: any}) => ({
  background: theme.palette.background.paper,
  borderRadius: 2, //16
  boxShadow: "unset",
  color: theme.palette.text.primary,
 
}));
export const Legend = styled(CircleSharp)(
  ({ theme, lgColor }: { theme?: any; lgColor: string }) => ({
    width: 12,
    height: 12,
    marginRight: 8,
    color: lgColor,
  })
);
export const CustomSelect = styled(Select)(
  ({ theme, lgColor }: { theme?: any; lgColor: string }) => ({
    border: "unset",
    margin: "4px 8px",
  })
);


const ToolBar = (props:any) => {
  const {open}= props;
  const theme = useTheme();
  const [service, setService] = React.useState("Khumbu");
  const [provider, setProvider] = React.useState("Providers");
  const [partner, setPartner] = React.useState("Partners");

  const { metadata } = useSelector((state: RootState) => ({
    metadata:state.logs.metadata.metadata,
  }));

  const serviceOptions = ["Khumbu"];
  const providerOptions = metadata?.filter((p:any) => p.entityType === "Providers").map((a:any)=>a.entity)[0];
  const partnerOptions = metadata?.filter((p:any) => p.entityType === "Partners").map((a:any)=>a.entity)[0]   
 
  const handleChangeServices = (event: SelectChangeEvent) => {
    setService(event.target.value);
  };
  const handleChangeProviders = (event: SelectChangeEvent) => {
    setProvider(event.target.value);
  };
  const handleChangePartner = (event: SelectChangeEvent) => {
    setPartner(event.target.value);
  };

  return (
    <AppBarWrap position="fixed"
        sx={{
          top: 16, right: 16, 
          left: open ? 262: 110, 
          width: open ? "calc(100vw - 278px)" : "calc(100vw - 125px)"
        }}
    >
      <Toolbar>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <ToolbarSelect options={providerOptions} />
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2">Statuses:</Typography>
            <DropdownSelect 
              value={service}
              handelChange={handleChangeServices}
              options={serviceOptions}
            />
            <DropdownSelect 
                value={provider}
                handelChange={handleChangeProviders}
                options={providerOptions}
              />
            <DropdownSelect 
              value={partner}
              handelChange={handleChangePartner}
              options={partnerOptions}
            />
          </Box>
{/* 
          <Box display="flex" alignItems="center">
            <Typography></Typography>
            <NotificationAddSharp htmlColor="#0066FF" />
          </Box> */}
        </Box>
      </Toolbar>
    </AppBarWrap>
  );
};

export default ToolBar;
