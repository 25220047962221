import { useTheme } from "@emotion/react";
import React from "react";

const NextIcon = (props: any) => {
  const { fill, fill1 } = props;
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" fill={fill1 || "white" } />
      <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" stroke="#D8DEEB" />
      <path
        d="M21.3538 18.354L16.3538 23.354C16.3073 23.4005 16.2522 23.4373 16.1915 23.4625C16.1308 23.4876 16.0657 23.5006 16 23.5006C15.9343 23.5006 15.8693 23.4876 15.8086 23.4625C15.7479 23.4373 15.6927 23.4005 15.6463 23.354C15.5998 23.3076 15.563 23.2524 15.5378 23.1917C15.5127 23.131 15.4998 23.066 15.4998 23.0003C15.4998 22.9346 15.5127 22.8695 15.5378 22.8088C15.563 22.7481 15.5998 22.693 15.6463 22.6465L20.2932 18.0003L15.6463 13.354C15.5525 13.2602 15.4998 13.133 15.4998 13.0003C15.4998 12.8676 15.5525 12.7403 15.6463 12.6465C15.7401 12.5527 15.8674 12.5 16 12.5C16.1327 12.5 16.26 12.5527 16.3538 12.6465L21.3538 17.6465C21.4003 17.693 21.4372 17.7481 21.4623 17.8088C21.4875 17.8695 21.5004 17.9346 21.5004 18.0003C21.5004 18.066 21.4875 18.131 21.4623 18.1917C21.4372 18.2524 21.4003 18.3076 21.3538 18.354Z"
        fill={fill || "#1B2121"}
      />
    </svg>
  );
};

export default NextIcon;
