import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { APP_ROUTES } from './utils/constants';
import { useSelector } from 'react-redux';
import { RootState } from './reducer';

// @ts-ignore: Unreachable code error
export function PrivateRoute(props: any) {
  const { component: Component, ...rest } = props;
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  const isAuthenticated = localStorage.getItem('isloggedIn') === 'true'
  // const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to={APP_ROUTES.login} />
      }
    />
  );
}
