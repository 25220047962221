// import { Snackbar, makeStyles, createStyles } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React from "react";
import { useDispatch } from "react-redux";
import { resetAlert } from "../../actions";
import {Alert, Slide, SlideProps, Snackbar, useTheme } from "@mui/material";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" ref={(node) => {
    if (node) {
      // Only act if node is valid
      // console.log("Transition node is valid:", node);
    }
  }} />;
}

// function Alert(props: AlertProps) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const Snack: React.FC<any> = ({ open, alert }) => {

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={()=>handleClose}
      sx={{
        maxWidth: 364,
        zIndex: 9999,
      }}
    >
      <Alert 
        elevation={6} 
        onClose={handleClose} 
        severity={alert.snackType} //alert.snackType
        variant='filled' 
        sx={{ width: '100%',
          '&.MuiAlert-filledError':{
            background: "#fdeded",
            color: theme.palette.error.dark,
          },
          '&.MuiAlert-filledSuccess':{
            background: "#edf7ed",
            color: theme.palette.success.dark,
          },
          '&.MuiAlert-filledInfo':{
            background: "#e5f6fd",
            color: theme.palette.info.dark,
          },
          '&.MuiAlert-filledWarning':{
            background:"#fff4e5",
            color: theme.palette.warning.main
          }
        }}
      >
        {alert.text}
      </Alert>
    </Snackbar>
  );
};

export default Snack;
