import { useSelector } from "react-redux";
import React from "react";
import { RootState } from "../../reducer";

const useAlert = () => {
  const { alert } = useSelector((state: RootState) => state.userInfo);

  const [showAlert, setShowAlert] = React.useState(false);
  const [showSnack, setShowSnack] = React.useState(false);

  // To open alert/snack dispatch the alert with options
  // To close the alert/snack dispatch resetAlert
  // resetAlert() will be called automatically in the Snack and ModalAlert
  // component, no need to call explicitly.

  // After calling resetAlert you dont need to manually close the Alert, It will
  // be handled automatically using this hook.

  React.useEffect(() => {
    if (showAlert) {
      setShowSnack(false);
    }
  }, [showAlert]);

  React.useEffect(() => {
    if (alert.text) {
      if (alert.snack) {
        setShowSnack(true);
      } else {
        setShowAlert(true);
      }
    } else {
      setShowAlert(false);
      setShowSnack(false);
    }
    if (alert.snackTimeout) {
      setTimeout(() => setShowSnack(false), alert.snackTimeout);
    }
  }, [alert]);

  return { showAlert, setShowAlert, showSnack, setShowSnack, alert };
};

export default useAlert;
