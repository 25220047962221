import moment from "moment-timezone";


export const APP_ROUTES = {
    dashboard: '/dashboard',
    status: '/status',
    analytics: '/analytics',
    logs: '/logs',
    integrations: '/integrations',
    newIntegration: '/integrations/new',
    integrationSettings: '/integrations/settings',
    gettingStarted: '/gettingStarted',
    invoices: '/invoices',
    users: '/users',
    settings: '/settings',
    support: '/support',
    // tenantDataIngested: '/tenants/:tenantId/data-ingested',
    // tenantUserAccount: '/tenants/:tenantId/user-accounts',
    login: '/login'
  };

export enum FILTER_TYPE  {
  type = "KhumbuService",
  subType = "API",
  providersName = "Providers" ,
  partnersName = "Partners",
  status = "Status"
}

export const TIMEZONE = moment.tz.guess();

export const VIEWMODE = {
  LIGHT:"light",
  DARK: "dark"
}

export const INTEGRATIONS_STEPS_ENUM = {
  SELECT_PROVIDER: "SELECT_PROVIDER",
  SELECT_PARTNER: "SELECT_PARTNER",
  PROVIDER_CONFIG: "PROVIDER_CONFIG",
  PARTNER_CONFIG: "PARTNER_CONFIG"
}

export const INTEGRATIONS_STEPS: any = {
  SELECT_PROVIDER: {
    step: "Select Povider",
    nextStepText: "Next: Select Partner",
    nextStep: "SELECT_PARTNER"
  },
  SELECT_PARTNER: {
    step: "Select Partner",
    nextStepText: "Next: Provider Config",
    nextStep: "PROVIDER_CONFIG"
  },
  PROVIDER_CONFIG: {
    step: "Provider Config",
    nextStepText: "Next: Partner Config",
    nextStep: "PARTNER_CONFIG"
  },
  PARTNER_CONFIG: {
    step: "Partner Config",
    nextStepText: "Confirm",
    // nextStep: "SELECT_PARTNER"
  },
}

export const initialAlert = {
  title: "",
  text: "",
  buttonText: "",
  icon: null,
  onHandlePress: null,
  component: null,
  buttonText2: "",
  onHandlePress2: null,
  subtitle: "",
  snackType: undefined,
  snack: false,
  noTextMargin: false,
  showText: false,
  notCloseOnSubmit: false,
  inputType: "text",
  buttonWrapperStyle: {},
  buttonStyle: {},
  allowedText: "",
  inputText: "",
  onModelClose: null,
};

export const errorMessages: any = {
  204: "I’m performing deep research on your content to ensure I can be as valuable to you as possible! Please check back here.",
  400: `Sorry I could not find enough content to populate the data here.`,
  500: `We are experiencing a brief interruption. We promise to be back shortly and apologize for the inconvenience. Thank you for your patience`,
  404: `Sorry I could not find enough content to populate the data here.`,
};

