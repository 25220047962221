import { post, get, putWithHeader, getWithHeader } from "./common";
// import config from "../config";
import { MyThunkAction } from "../types";
import { isEmpty } from "lodash";



export const GET_INTEGRATION_DATA = "GET_INTEGRATION_DATA ";
export const SET_PAYLOAD_DATA = "SET_PAYLOAD_DATA";
export const GET_INTEGRATION_METADATA = "GET_INTEGRATION_METADATA";
export const GET_CONFIG_METADATA = "GET_CONFIG_METADATA";
export const GET_PARTNER_METADATA = "GET_PARTNER_METADATA";
export const SET_CURRENT_STEP= "SET_CURRENT_STEP";
export const ON_DATA_LOADING_START = "ON_DATA_LOADING_START";
export const ON_DATA_LOADING_FINISHED = "ON_DATA_LOADING_FINISHED";

export const setCurrentStep = (queryString?:any) => ({
  type:SET_CURRENT_STEP,
  payload: queryString
});

export const setIntegrationQueryData = (queryString?:any,forceReset?:boolean) => ({
  type:SET_PAYLOAD_DATA,
  payload: queryString
});

export const getIntegratedData = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => { 

  const data = null; // Add query params if needed
  const headers = queryString;

  dispatch({ type: ON_DATA_LOADING_START, payload:true });

  const response = await getWithHeader(`/tz-portal-api-dev/v1/integrations/partner`,queryString); 

  dispatch({
    type: GET_INTEGRATION_DATA ,
    payload: {
      forceReset,
      queryString: queryString,
      response: response
    },
  });
  console.log(response);
  dispatch({ type: ON_DATA_LOADING_FINISHED, payload:false });

  return { status: 200 };
};

export const getIntegrationdMetaData = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

  const response = await get(`/tz-portal-api-dev/v1/integrations/partner/metadata`)  

  dispatch({
    type: GET_INTEGRATION_METADATA ,
    payload:  response
  });
  return { status: 200 };
};

export const getConfigMetaData = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {  

    const response = await getWithHeader(`/tz-portal-api-dev/v1/integrations/config/metadata`,queryString) 
      dispatch({
        type: GET_CONFIG_METADATA,
        payload: {
          forceReset,
          response: response
        },
    
      });
    return { status: 200 };
};

export const getPartnerConfigMetaData = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {  

  const response = await get(`/tz-portal-api-dev/v1/integrations/config/metadata/${queryString}`,) 
  
    dispatch({
      type: GET_PARTNER_METADATA,
      payload: {
        forceReset,
        response: response
      },
  
    });
  
  return { status: 200 };
};







