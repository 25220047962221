import React from "react";

const NoDataIcon = (props: any) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="99"
      viewBox="0 0 121 99"
      fill="none"
    >
      <path
        d="M111.629 79.0738L93.8162 61.243C98.9791 55.0372 101.554 47.0774 101.004 39.0195C100.455 30.9616 96.8235 23.426 90.866 17.9803C84.9085 12.5346 77.0834 9.59804 69.0185 9.78155C60.9535 9.96506 53.2697 13.2545 47.5655 18.9655C41.8613 24.6766 38.5758 32.3696 38.3925 40.4441C38.2092 48.5187 41.1422 56.3532 46.5814 62.3178C52.0206 68.2824 59.5472 71.9179 67.5955 72.4681C75.6438 73.0182 83.5941 70.4406 89.7925 65.2715L107.602 83.1059C107.866 83.3706 108.18 83.5806 108.526 83.7239C108.871 83.8672 109.242 83.9409 109.616 83.9409C109.99 83.9409 110.36 83.8672 110.705 83.7239C111.051 83.5806 111.365 83.3706 111.629 83.1059C111.894 82.8411 112.103 82.5268 112.247 82.1809C112.39 81.835 112.463 81.4642 112.463 81.0898C112.463 80.7154 112.39 80.3447 112.247 79.9988C112.103 79.6529 111.894 79.3386 111.629 79.0738ZM44.1552 41.1967C44.1552 36.1245 45.6575 31.1662 48.4721 26.9488C51.2867 22.7314 55.2872 19.4444 59.9678 17.5033C64.6483 15.5623 69.7986 15.0544 74.7674 16.044C79.7362 17.0335 84.3003 19.476 87.8826 23.0626C91.465 26.6492 93.9045 31.2188 94.8929 36.1935C95.8813 41.1683 95.374 46.3248 93.4353 51.0109C91.4965 55.697 88.2134 59.7023 84.001 62.5203C79.7887 65.3382 74.8363 66.8423 69.7702 66.8423C62.979 66.8348 56.4681 64.1304 51.666 59.3226C46.8639 54.5147 44.1628 47.9961 44.1552 41.1967Z"
        fill="#6C7A8E"
      />
      <rect
        x="23.4193"
        y="24.75"
        width="39.0323"
        height="26.0526"
        fill="white"
      />
      <ellipse
        cx="20.8172"
        cy="20.8429"
        rx="11.7097"
        ry="10.4211"
        fill="white"
      />
      <rect
        x="9.10754"
        y="27.3555"
        width="11.7097"
        height="5.21053"
        rx="2.60526"
        fill="#D9D9D9"
      />
      <rect
        x="23.4193"
        y="27.3555"
        width="31.2258"
        height="5.21053"
        rx="2.60526"
        fill="#C2C5CA"
      />
      <rect
        x="9.10754"
        y="42.9863"
        width="11.7097"
        height="5.21053"
        rx="2.60526"
        fill="#C2C5CA"
      />
      <rect
        x="23.4193"
        y="42.9863"
        width="31.2258"
        height="5.21053"
        rx="2.60526"
        fill="#C2C5CA"
      />
      <rect
        x="68.957"
        y="40.3809"
        width="11.7097"
        height="5.21053"
        rx="2.60526"
        transform="rotate(-180 68.957 40.3809)"
        fill="#C2C5CA"
      />
      <rect
        x="54.6451"
        y="40.3809"
        width="31.2258"
        height="5.21053"
        rx="2.60526"
        transform="rotate(-180 54.6451 40.3809)"
        fill="#C2C5CA"
      />
      <ellipse
        cx="68.957"
        cy="97.0457"
        rx="41.6344"
        ry="1.95395"
        fill="#E5E6E9"
      />
      <path
        d="M20.8172 3.9082C17.4719 3.9082 14.2018 4.90138 11.4203 6.76213C8.63879 8.62289 6.47089 11.2676 5.19071 14.362C3.91053 17.4563 3.57557 20.8612 4.2282 24.1461C4.88083 27.431 6.49173 30.4484 8.8572 32.8167C11.2227 35.185 14.2364 36.7978 17.5174 37.4512C20.7984 38.1047 24.1993 37.7693 27.2899 36.4876C30.3805 35.2059 33.0221 33.0354 34.8806 30.2506C36.7392 27.4657 37.7312 24.1917 37.7312 20.8424C37.7264 16.3526 35.9429 12.0481 32.7719 8.87336C29.601 5.6986 25.3016 3.91294 20.8172 3.9082ZM26.942 25.1313C27.0629 25.2524 27.1588 25.396 27.2242 25.5542C27.2896 25.7123 27.3233 25.8818 27.3233 26.0529C27.3233 26.2241 27.2896 26.3936 27.2242 26.5517C27.1588 26.7098 27.0629 26.8535 26.942 26.9746C26.8211 27.0956 26.6776 27.1916 26.5197 27.2571C26.3617 27.3226 26.1924 27.3563 26.0215 27.3563C25.8505 27.3563 25.6813 27.3226 25.5233 27.2571C25.3654 27.1916 25.2219 27.0956 25.101 26.9746L20.8172 22.684L16.5334 26.9746C16.4125 27.0956 16.269 27.1916 16.1111 27.2571C15.9531 27.3226 15.7838 27.3563 15.6129 27.3563C15.4419 27.3563 15.2726 27.3226 15.1147 27.2571C14.9568 27.1916 14.8133 27.0956 14.6924 26.9746C14.5715 26.8535 14.4756 26.7098 14.4102 26.5517C14.3448 26.3936 14.3111 26.2241 14.3111 26.0529C14.3111 25.8818 14.3448 25.7123 14.4102 25.5542C14.4756 25.396 14.5715 25.2524 14.6924 25.1313L18.9778 20.8424L14.6924 16.5535C14.4482 16.3091 14.3111 15.9776 14.3111 15.6319C14.3111 15.2862 14.4482 14.9547 14.6924 14.7103C14.9365 14.4658 15.2676 14.3285 15.6129 14.3285C15.9581 14.3285 16.2893 14.4658 16.5334 14.7103L20.8172 19.0008L25.101 14.7103C25.2219 14.5892 25.3654 14.4932 25.5233 14.4277C25.6813 14.3622 25.8505 14.3285 26.0215 14.3285C26.1924 14.3285 26.3617 14.3622 26.5197 14.4277C26.6776 14.4932 26.8211 14.5892 26.942 14.7103C27.0629 14.8313 27.1588 14.975 27.2242 15.1331C27.2896 15.2912 27.3233 15.4607 27.3233 15.6319C27.3233 15.803 27.2896 15.9725 27.2242 16.1307C27.1588 16.2888 27.0629 16.4325 26.942 16.5535L22.6566 20.8424L26.942 25.1313Z"
        fill="#F71631"
      />
    </svg>
  );
};

export default NoDataIcon;
