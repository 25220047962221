import { combineReducers } from "redux";
// import globalSetting from "./globalSetting";
import dashboard from "./dashboard";
import logs from "./logs";
import scrollPagination from "./scrollPagination";
import analytics from "./analytics";
import integrations from "./integrations";
import userInfo from "./userInfo";

const initialAuthState = {
  isAuthenticated: false,
};

// Authentication reducer
const authReducer = (state = initialAuthState, action:any) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isAuthenticated: true };
    case 'LOGOUT':
      return { ...state, isAuthenticated: false };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  dashboard:dashboard,
  logs:logs,
  scrollPagination: scrollPagination,
  auth: authReducer,
  analytics:analytics,
  integrations: integrations,
  userInfo
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
