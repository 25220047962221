import React from "react";

const ArrowSquareInIcon = (props: any) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.0004 12.75V18.75C12.0004 18.9489 11.9214 19.1397 11.7807 19.2803C11.6401 19.421 11.4493 19.5 11.2504 19.5C11.0515 19.5 10.8607 19.421 10.7201 19.2803C10.5794 19.1397 10.5004 18.9489 10.5004 18.75V14.5613L4.28104 20.7806C4.21136 20.8503 4.12863 20.9056 4.03759 20.9433C3.94654 20.981 3.84896 21.0004 3.75042 21.0004C3.65187 21.0004 3.55429 20.981 3.46324 20.9433C3.3722 20.9056 3.28947 20.8503 3.21979 20.7806C3.15011 20.7109 3.09483 20.6282 3.05712 20.5372C3.01941 20.4461 3 20.3485 3 20.25C3 20.1515 3.01941 20.0539 3.05712 19.9628C3.09483 19.8718 3.15011 19.7891 3.21979 19.7194L9.43917 13.5H5.25042C5.0515 13.5 4.86074 13.421 4.72009 13.2803C4.57943 13.1397 4.50042 12.9489 4.50042 12.75C4.50042 12.5511 4.57943 12.3603 4.72009 12.2197C4.86074 12.079 5.0515 12 5.25042 12H11.2504C11.4493 12 11.6401 12.079 11.7807 12.2197C11.9214 12.3603 12.0004 12.5511 12.0004 12.75ZM19.5004 3H7.50042C7.10259 3 6.72106 3.15804 6.43976 3.43934C6.15845 3.72064 6.00042 4.10218 6.00042 4.5V9C6.00042 9.19891 6.07943 9.38968 6.22009 9.53033C6.36074 9.67098 6.5515 9.75 6.75042 9.75C6.94933 9.75 7.14009 9.67098 7.28075 9.53033C7.4214 9.38968 7.50042 9.19891 7.50042 9V4.5H19.5004V16.5H15.0004C14.8015 16.5 14.6107 16.579 14.4701 16.7197C14.3294 16.8603 14.2504 17.0511 14.2504 17.25C14.2504 17.4489 14.3294 17.6397 14.4701 17.7803C14.6107 17.921 14.8015 18 15.0004 18H19.5004C19.8982 18 20.2798 17.842 20.5611 17.5607C20.8424 17.2794 21.0004 16.8978 21.0004 16.5V4.5C21.0004 4.10218 20.8424 3.72064 20.5611 3.43934C20.2798 3.15804 19.8982 3 19.5004 3Z"
        fill={fill||"#1B2121"}
      />
    </svg>
  );
};

export default ArrowSquareInIcon;
