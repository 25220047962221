import React from "react";

const UploadIcon = (props: any) => {
  const { fill, onClick, style } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={style}
    >
      <path
        d="M14 9.00039V13.0004C14 13.133 13.9473 13.2602 13.8536 13.3539C13.7598 13.4477 13.6326 13.5004 13.5 13.5004H2.5C2.36739 13.5004 2.24021 13.4477 2.14645 13.3539C2.05268 13.2602 2 13.133 2 13.0004V9.00039C2 8.86778 2.05268 8.74061 2.14645 8.64684C2.24021 8.55307 2.36739 8.50039 2.5 8.50039C2.63261 8.50039 2.75979 8.55307 2.85355 8.64684C2.94732 8.74061 3 8.86778 3 9.00039V12.5004H13V9.00039C13 8.86778 13.0527 8.74061 13.1464 8.64684C13.2402 8.55307 13.3674 8.50039 13.5 8.50039C13.6326 8.50039 13.7598 8.55307 13.8536 8.64684C13.9473 8.74061 14 8.86778 14 9.00039ZM5.85375 4.85414L7.5 3.20727V9.00039C7.5 9.133 7.55268 9.26018 7.64645 9.35395C7.74021 9.44772 7.86739 9.50039 8 9.50039C8.13261 9.50039 8.25979 9.44772 8.35355 9.35395C8.44732 9.26018 8.5 9.133 8.5 9.00039V3.20727L10.1462 4.85414C10.2401 4.94796 10.3673 5.00067 10.5 5.00067C10.6327 5.00067 10.7599 4.94796 10.8538 4.85414C10.9476 4.76032 11.0003 4.63308 11.0003 4.50039C11.0003 4.36771 10.9476 4.24046 10.8538 4.14664L8.35375 1.64664C8.30731 1.60016 8.25217 1.56328 8.19147 1.53811C8.13077 1.51295 8.06571 1.5 8 1.5C7.93429 1.5 7.86923 1.51295 7.80853 1.53811C7.74783 1.56328 7.69269 1.60016 7.64625 1.64664L5.14625 4.14664C5.05243 4.24046 4.99972 4.36771 4.99972 4.50039C4.99972 4.63308 5.05243 4.76032 5.14625 4.85414C5.24007 4.94796 5.36732 5.00067 5.5 5.00067C5.63268 5.00067 5.75993 4.94796 5.85375 4.85414Z"
        fill={fill || "white"}
      />
    </svg>
  );
};

export default UploadIcon;
