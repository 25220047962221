import getPalette from './palette';
import typography from './typography';
import  { createCustomShadow } from './shadows';

const baseTheme: any = {
  getPalette,
  typography,
  createCustomShadow
};

// declare module '@mui/material/styles/createPalette' {
//   interface Palette {
//     chart: {
//       violet: string[];
//       blue: string[];
//       green: string[];
//       grey: string[];
//       orange: string[];
//       red: string[];
//     };
//   }

//   interface PaletteOptions {
//     chart: {
//       violet: string[];
//       blue: string[];
//       green: string[];
//       grey: string[];
//       orange: string[];
//       red: string[];
//     };
//   }
// }

export default baseTheme;
