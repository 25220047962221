import { post } from "./common";
// import config from "../config";
import { MyThunkAction } from "../types";



export const GET_ANALYTICS_DATA = "GET_ANALYTICS_DATA";
export const SET_ANALYTICS_QUERY_DATA = "SET_ANALYTICS_QUERY_DATA";


export const setAnalyticsQueryData = (queryString?:any,forceReset?:boolean) => ({
  type:SET_ANALYTICS_QUERY_DATA,
  payload: queryString
});

export const getAnalyticsData = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

  const response = await post(`/tz-portal-api-dev/v1/metrics/analytics`,queryString)  

  dispatch({
    type: GET_ANALYTICS_DATA,
    payload: {
      forceReset,
      queryString: queryString,
      response: response
    },

  });

  return { status: 200 };
};





