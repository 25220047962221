const { BACKEND_BASE_URL, CORS_URL,} = window;
delete window.BACKEND_BASE_URL;
delete window.CORS_URL;

const config = {
  BASE_URL: `https://api.dev.khumbu.com`,
  ACTUAL_URL: `https://api.dev.khumbu.com`,
  API_KEY:process.env.REACT_APP_API_KEY,
  "region": "us-east-1",
  "userPoolId": "us-east-1_AtB8qjAOJ",
  "clientId": "6p7s744beaelufokrie8vnf55c"
};

export default config;
