import { useTheme } from "@mui/material";
import React from "react";

const KhumbuLogo = (props: any) => {
  const {style, fill} = props
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="143"
      height="28"
      viewBox="0 0 143 28"
      fill="none"
      style={style}
    >
      <path
        d="M27.9628 9.31716L18.6418 0L9.32089 9.31716L18.6418 18.6343L27.9628 9.31716Z"
        fill="#53C8ED"
      />
      <path
        d="M18.642 18.6345L9.32104 9.31738L8.91229e-05 18.6345L9.32104 27.9517L18.642 18.6345Z"
        fill="#12B1E7"
      />
      <path
        d="M9.32098 18.6343H18.6419L9.32098 27.9514L0 18.6343H9.32098Z"
        fill="#00A17A"
      />
      <path
        d="M37.2838 18.6345L27.9629 9.31738L18.6419 18.6345L27.9629 27.9517L37.2838 18.6345Z"
        fill="#F2CF38"
      />
      <path
        d="M27.9628 18.6343H37.2837L27.9628 27.9514L18.6418 18.6343H27.9628Z"
        fill="#F58546"
      />
      <path
        d="M135.184 23.8465C132.266 23.8465 130.38 21.8585 130.38 18.812V9.95654H133.454V18.1924C133.454 19.9222 134.487 21.084 136.062 21.084C137.819 21.084 139.162 19.6382 139.162 17.7536V9.95654H142.236V23.4592H139.291V21.7811C138.542 22.9429 136.863 23.8465 135.184 23.8465Z"
        fill={fill  || theme.palette.text.primary}
      />
      <path
        d="M123.044 23.8466C121.211 23.8466 119.686 23.072 118.808 21.7553V23.4593H115.838V4.2251H118.911V11.5315C119.816 10.2923 121.288 9.56938 123.044 9.56938C126.582 9.56938 129.062 12.5126 129.062 16.695C129.062 20.9034 126.582 23.8466 123.044 23.8466ZM122.424 21.0583C124.593 21.0583 125.937 19.3543 125.937 16.695C125.937 14.0358 124.568 12.3577 122.424 12.3577C120.306 12.3577 118.911 14.0874 118.911 16.695C118.911 19.3801 120.28 21.0583 122.424 21.0583Z"
        fill={fill  || theme.palette.text.primary}
      />
      <path
        d="M94.1829 23.4593V9.95664H97.1529V11.6347C97.8762 10.4214 99.3996 9.56934 101.027 9.56934C102.758 9.56934 104.126 10.3697 104.875 11.7897C105.702 10.5246 107.51 9.56934 109.189 9.56934C112.004 9.56934 113.812 11.5315 113.812 14.6038V23.4593H110.738V15.0943C110.738 13.3903 109.808 12.3319 108.362 12.3319C106.709 12.3319 105.521 13.6485 105.521 15.4816V23.4593H102.448V15.0943C102.448 13.3903 101.543 12.3319 100.097 12.3319C98.4439 12.3319 97.2559 13.6227 97.2559 15.4816V23.4593H94.1829Z"
        fill={fill  || theme.palette.text.primary}
      />
      <path
        d="M85.1253 23.8465C82.2064 23.8465 80.321 21.8585 80.321 18.812V9.95654H83.3944V18.1924C83.3944 19.9222 84.4275 21.084 86.0036 21.084C87.7592 21.084 89.1029 19.6382 89.1029 17.7536V9.95654H92.1759V23.4592H89.2315V21.7811C88.4826 22.9429 86.8035 23.8465 85.1253 23.8465Z"
        fill={fill  || theme.palette.text.primary}
      />
      <path
        d="M66.283 23.4593V4.2251H69.3565V11.454C70.183 10.3697 71.836 9.56938 73.4632 9.56938C76.4592 9.56938 78.3964 11.5315 78.3964 14.6038V23.4593H75.3228V15.2235C75.3228 13.4678 74.238 12.3318 72.585 12.3318C70.7771 12.3318 69.3565 13.7776 69.3565 15.6366V23.4593H66.283Z"
        fill={fill  || theme.palette.text.primary}
      />
      <path
        d="M50.0049 23.4594V4.87061H53.1043V13.2871L61.2144 4.87061H65.1402L57.8825 12.4352L65.5793 23.4594H61.9892L55.6872 14.7329L53.1043 17.418V23.4594H50.0049Z"
        fill={fill  || theme.palette.text.primary}
      />
    </svg>
  );
};

export default KhumbuLogo;
