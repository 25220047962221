import { useTheme } from "@mui/material";
import React from "react";

const KhumbuLogoIcon = (props: any) => {
  const { style } = props;
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="28"
      viewBox="0 0 45 28"
      fill="none"
    >
      <path
        d="M27.9628 9.31716L18.6418 0L9.32089 9.31716L18.6418 18.6343L27.9628 9.31716Z"
        fill="#53C8ED"
      ></path>
      <path
        d="M18.642 18.6345L9.32104 9.31738L8.91229e-05 18.6345L9.32104 27.9517L18.642 18.6345Z"
        fill="#12B1E7"
      ></path>
      <path
        d="M9.32098 18.6343H18.6419L9.32098 27.9514L0 18.6343H9.32098Z"
        fill="#00A17A"
      ></path>
      <path
        d="M37.2838 18.6345L27.9629 9.31738L18.6419 18.6345L27.9629 27.9517L37.2838 18.6345Z"
        fill="#F2CF38"
      ></path>
      <path
        d="M27.9628 18.6343H37.2837L27.9628 27.9514L18.6418 18.6343H27.9628Z"
        fill="#F58546"
      ></path>
    </svg>
  );
};

export default KhumbuLogoIcon;
