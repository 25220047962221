import React from "react";

const TrendingUpIcon = (props: any) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.632 4L12.1537 5.52667L8.91099 8.78L6.25301 6.11333L1.3291 11.06L2.26604 12L6.25301 8L8.91099 10.6667L13.0973 6.47333L14.619 8V4H10.632Z"
        fill="#07DB3E"
      />
    </svg>
  );
};

export default TrendingUpIcon;
