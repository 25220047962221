import React from "react";

const KumbhuIcon = (props: any) => {
  const { fill, stroke, style } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" style={style}>
      <path d="M31.9617 19.9838L23.9746 12L15.9875 19.9838L23.9746 27.9677L31.9617 19.9838Z" fill="#53C8ED"/>
      <path d="M23.9744 27.9677L15.9873 19.9839L8.0002 27.9677L15.9873 35.9516L23.9744 27.9677Z" fill="#12B1E7"/>
      <path d="M15.9871 27.9683H23.9742L15.9871 35.9521L8 27.9683H15.9871Z" fill="#00A17A"/>
      <path d="M39.948 27.9677L31.9609 19.9839L23.9738 27.9677L31.9609 35.9516L39.948 27.9677Z" fill="#F2CF38"/>
      <path d="M31.9617 27.9683H39.9488L31.9617 35.9521L23.9746 27.9683H31.9617Z" fill="#F58546"/>
      <rect x="0.5" y="0.5" width="47" height="47" rx="7.5" stroke={stroke || "#D8DEEB"}/>
    </svg>
  );
};

export default KumbhuIcon;
