import * as React from "react";
import {Avatar, Box, MenuItem, Select, SelectChangeEvent, styled, Typography, useTheme} from "@mui/material";
import {CircleSharp, ExpandMore, Height, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import { DoordashLogo, EatStreetLogo, GrubhubLogo, KhumbuLogo, KhumbuLogoIcon, UberEatsLogo } from "../SvgIcons";
import { isEmpty } from "lodash";
import { stringAvatar } from "../../utils/utils";
import { StyledAvatar } from "../commonStyles";

export const Legend = styled(CircleSharp)(
  ({ theme, lgColor }: { theme?: any; lgColor: string }) => ({
    width: 12,
    height: 12,
    marginRight: 8,
    color: lgColor,
  })
);
export const CustomSelect = styled(Select)(
  ({ theme, lgColor }: { theme?: any; lgColor: string }) => ({
    border: "unset",
    margin: "4px 8px",
  })
);
export const MenuList = styled(MenuItem)(
  ({ theme, }: { theme?: any; }) => ({
    display: "flex",
    alignContent: "center",
    fontSize: 18, 
    fontWeight: 600,
    padding: "12px 20px",
    textTransform: "capitalize",
    letterSpacing:.5,
    alignItem: "center",
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: "100%",
    "&:hover":{
      background: theme.palette.background.paper
    },
    "& svg":{
      width: 36,
      height:36,
    },
    "&.Mui-selected":{
      backgroundColor: theme.palette.primary.lighter,
      color: theme.palette.text.primary,
      padding: "12px 20px",
      textTransform: "capitalize",
      fontWeight:600,
      letterSpacing:.5,
    },
    "&:last-child":{
      border: "unset"
    },
  })
);




type ToolbarSelectProps = {
    classes?: any,
    options?:any,
}

const ToolbarSelect:React.FC<ToolbarSelectProps> = ({
    classes,
    options
}) => {
  const theme: any = useTheme();
  const [value, setValue] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeUsers = (event: SelectChangeEvent) => {
    
    if(value !== event.target.value ){
      setValue(event.target.value);
    }else{
      setValue("")
    }

  }

  const allProviders= [{
    name: "Khumbu", 
      icon: <KhumbuLogoIcon />,
    value: "Khumbu"
  }];

  const items = options?.map((a:any)=>{
    return {name: a, 
      icon: <StyledAvatar {...stringAvatar(a)} sx={{mr:2}} name={a}/>,
      value: a
      }
    
  })  
  
  return (
    <Select
        value={value}
        variant="standard"
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChangeUsers}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={()=>
          open ? (
          <KeyboardArrowUp onClick={handleClose} sx={{mr: -7}} />
        ) : (
          <KeyboardArrowDown onClick={handleOpen} sx={{mr: -7}} />
        )}
        disableUnderline
        sx={{
        "& .MuiSelect-select":{
            paddingRight: 8,
            textTransform: "capitalize",
            fontWeight:600,
            letterSpacing:.7,
            "&:focus":{
            backgroundColor: "transparent",
          }
        }
        }}
        
        MenuProps= {{
         
          PaperProps: {
            sx: {
              width: "auto",
              backgroundImage: 'unset !important',
              textAlign:" center",
              "& .Mui-selected":{
                backgroundColor: `${theme.palette.background.paper} !important`,
                padding: 0,
                "&:hover":{
                  backgroundColor: theme.palette.background.paper,
                }
              },
              "&:hover":{
                  backgroundColor: theme.palette.background.paper,
                }
            }
          },
        }}
        renderValue={(selected) => {
          return <>
              {isEmpty(value) || value === "Khumbu"? 

                <Typography sx={{display:"flex", alignItems:"center", fontSize:18, fontWeight:600}}>
                  <KhumbuLogoIcon style={{width:36, marginRight: 0}} />
                    Khumbu
                </Typography>
                :<Typography variant="subtitle1" display="flex" 
                  alignItems="center" color={theme.palette.text.drawer} 
                  fontSize={18} fontWeight={600}>
                  <StyledAvatar {...stringAvatar(selected)} 
                      sx={{mr:1, fontSize: 14}} name={selected}/> 
                    {selected}
                </Typography>
              }
            </>
        }}  
    >   
        {/* <MenuList value={'khumbu'} sx={{textAlign:" center", }} onClick={(e: any) => handleChangeUsers(e)}>
            <KhumbuLogoIcon style={{width:36}} /> Khumbu
        </MenuList> */}
        {items?.map((item:any, index:number)=>{
          return(
            
              <MenuList key={index} value={item.value} sx={{textAlign:" center",}} onClick={(e: any) => handleChangeUsers(e)}>
                {item.name === "Khumbu" ?
                  <><KhumbuLogoIcon style={{width:36}} /> {item.name}</>
                :
                  <>
                  <StyledAvatar {...stringAvatar(item.name)} name={item.name} sx={{mr:1}}/> {item.name}</>
                }
                  
              </MenuList>
          );
        })}
    </Select>    
  );
};

export default ToolbarSelect;
