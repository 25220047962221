export const RESET_PAGINATON_STATE = "RESET_PAGINATON_STATE";
export const SET_PAGINATION_OFFSET = "SET_PAGINATION_OFFSET";
export const ON_PAGINATION_LIST_LOADING = "ON_PAGINATION_LIST_LOADING";
export const ON_PAGINATION_LIST_RESPONSE = "ON_PAGINATION_LIST_RESPONSE";
export const ON_ITEM_DELETE_SUCCESS = "ON_ITEM_DELETE_SUCCESS";
export const ON_PAGINATION_LIST_LOCAL_RESPONSE = "ON_PAGINATION_LIST_LOCAL_RESPONSE";
export const ON_PAGINATION_LIST_LOCAL = "ON_PAGINATION_LIST_LOCAL";

export const resetPagiationState = () => ({
  type: RESET_PAGINATON_STATE,
});

export const setPagiationOffset = (payload: any) => ({
  type: SET_PAGINATION_OFFSET,
  payload,
});
