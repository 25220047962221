import React from "react";

const PrevIcon = (props: any) => {
  const { fill, fill1 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" fill={fill1 || "white"} />
      <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" stroke="#D8DEEB" />
      <path
        d="M20.3537 22.6465C20.4001 22.693 20.437 22.7481 20.4621 22.8088C20.4872 22.8695 20.5002 22.9346 20.5002 23.0003C20.5002 23.066 20.4872 23.131 20.4621 23.1917C20.437 23.2524 20.4001 23.3076 20.3537 23.354C20.3072 23.4005 20.252 23.4373 20.1914 23.4625C20.1307 23.4876 20.0656 23.5006 19.9999 23.5006C19.9342 23.5006 19.8692 23.4876 19.8085 23.4625C19.7478 23.4373 19.6926 23.4005 19.6462 23.354L14.6462 18.354C14.5997 18.3076 14.5628 18.2524 14.5376 18.1917C14.5125 18.131 14.4995 18.066 14.4995 18.0003C14.4995 17.9346 14.5125 17.8695 14.5376 17.8088C14.5628 17.7481 14.5997 17.693 14.6462 17.6465L19.6462 12.6465C19.74 12.5527 19.8672 12.5 19.9999 12.5C20.1326 12.5 20.2598 12.5527 20.3537 12.6465C20.4475 12.7403 20.5002 12.8676 20.5002 13.0003C20.5002 13.133 20.4475 13.2602 20.3537 13.354L15.7068 18.0003L20.3537 22.6465Z"
        fill={fill || "#1B2121"}
      />
    </svg>
  );
};

export default PrevIcon;
