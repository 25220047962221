import { ActionType } from "./types";
import * as actionTypes from "../actions";

type DashboardTypes = {
  dashboardData: any,
  isLoading: boolean
}

const initialState: DashboardTypes = {
  dashboardData: [],
  isLoading: true
  
};

const dashboard = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.ON_DASHBOARD_LOAD: {
      return {
        ...state,
        dashboardData: action.payload,
      };
    }
    case actionTypes.ON_STATS_LOADING_START: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case actionTypes.ON_STATS_LOADING_FINISHED: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default dashboard;
