import React from "react";

const DashboardIcon = (props: any) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.4119 6.81285C18.4397 5.83846 17.2842 5.06617 16.0121 4.54049C14.74 4.01482 13.3764 3.74617 12 3.75004H11.9625C6.19406 3.76973 1.5 8.53129 1.5 14.356V16.5C1.5 16.8979 1.65804 17.2794 1.93934 17.5607C2.22064 17.842 2.60218 18 3 18H21C21.3978 18 21.7794 17.842 22.0607 17.5607C22.342 17.2794 22.5 16.8979 22.5 16.5V14.25C22.5039 12.8676 22.2328 11.4982 21.7027 10.2214C21.1725 8.94461 20.3938 7.78598 19.4119 6.81285ZM21 16.5H11.2228L16.3566 9.44067C16.4737 9.2798 16.5221 9.07899 16.4911 8.88243C16.4602 8.68587 16.3524 8.50965 16.1916 8.39254C16.0307 8.27543 15.8299 8.22702 15.6333 8.25797C15.4368 8.28891 15.2605 8.39667 15.1434 8.55754L9.3675 16.5H3V14.356C3 14.0672 3.01406 13.7822 3.04031 13.5H5.25C5.44891 13.5 5.63968 13.421 5.78033 13.2804C5.92098 13.1397 6 12.949 6 12.75C6 12.5511 5.92098 12.3604 5.78033 12.2197C5.63968 12.0791 5.44891 12 5.25 12H3.30656C4.27406 8.35692 7.43156 5.60254 11.25 5.28192V7.50004C11.25 7.69895 11.329 7.88972 11.4697 8.03037C11.6103 8.17102 11.8011 8.25004 12 8.25004C12.1989 8.25004 12.3897 8.17102 12.5303 8.03037C12.671 7.88972 12.75 7.69895 12.75 7.50004V5.28098C14.6106 5.43756 16.3767 6.16813 17.8042 7.37175C19.2316 8.57537 20.2501 10.1926 20.7188 12H18.75C18.5511 12 18.3603 12.0791 18.2197 12.2197C18.079 12.3604 18 12.5511 18 12.75C18 12.949 18.079 13.1397 18.2197 13.2804C18.3603 13.421 18.5511 13.5 18.75 13.5H20.9691C20.9888 13.7485 21 13.9979 21 14.25V16.5Z"
        fill={fill || "#1B2121"}
      />
    </svg>
  );
};

export default DashboardIcon;
