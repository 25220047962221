import { styled} from '@mui/material/styles';
import { Box } from '@mui/material';

export const drawerWidth = 240;


export const MainLayout = styled(Box)(
    ({ theme}: { theme: any }) => ({
      background: theme.palette.background.default,
      height: "100vh"
      }
    )
  );

  