import React from "react";

const TrendingDownIcon = (props: any) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.632 12L12.1537 10.4733L8.91099 7.22L6.25301 9.88667L1.3291 4.94L2.26604 4L6.25301 8L8.91099 5.33333L13.0973 9.52667L14.619 8V12H10.632Z"
        fill="#F71631"
      />
    </svg>
  );
};

export default TrendingDownIcon;
