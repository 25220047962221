import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Warning } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { ContainedButton, SecondaryButton } from './commonStyles';


type AlertDialogProps = {
  classes?: string;
  handleClose:any,
  handleLeave:any
};

const AlertDialog:React.FC<AlertDialogProps> = ({
  handleClose,
  handleLeave
}) => {
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
        </DialogTitle>
        <DialogContent>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} padding={2}>
            <Warning htmlColor={theme.palette.warning.main} />
            <Typography textAlign={"center"} pt={1}>
              You have unsaved changes. <br/>
              Save your progress before leaving?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{p:2}}>
          <ContainedButton onClick={handleClose}>
            Stay
          </ContainedButton>
          <SecondaryButton onClick={handleLeave} autoFocus>Leave</SecondaryButton>
        </DialogActions>
      </Dialog>
  );
}
export default AlertDialog;