import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '../Drawer/index';
import ToolBar from '../ToolBar';
import {
  MainLayout
} from './styles';
import { CssBaseline } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getmetaData } from '../../actions';
import { TIMEZONE } from '../../utils/constants';
import Snack from '../Snack';
import useAlert from '../Snack/useAlert';

const drawerWidth = 240;

type LayoutProps = {
  classes?: string;
  children: any
};

const Layout: React.FC<LayoutProps>= ({ children })=>{

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const { showAlert, alert, showSnack } = useAlert();  

  // console.log(showAlert, alert, showSnack);
  

  const handleDrawerOpen = () => {
    setOpen(!open);
  };  
  
  return (
    
    <MainLayout sx={{ display: 'flex', overflow: "hidden", }}>
      <CssBaseline />
      <Snack open={showSnack} alert={alert} />
      {location.pathname !== "/login" &&
        <>
          <ToolBar open={open} />
          <Drawer handleDrawerOpen = {handleDrawerOpen} />
        </>
      }
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // p: 1,
          pb: 0,
          mt: location.pathname !== "/login" ? 9: 0,
          height: location.pathname !== "/login" ? "calc(100vh - 88px)": "calc(100vh - 32px)",
          overflow: "auto",
          // width: 'calc(100% - 240px)',
        }}
      >  
          {children}
      </Box>
        
      </Box>
      
    </MainLayout>
  );
}

export default Layout;
